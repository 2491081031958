<div class="ab4-wrapper bg-lightblue" *ngIf="tabs?.length">
    <div class="container-xxxl">
        <div class="row">
            <div class="col">
                <h2 class="text-blue panel-title mt-5 mb-4" *ngIf="sectionHeader" [innerHTML]="sectionHeader"></h2>
            </div>
        </div>
    </div>
    <button-detail-list-larger [tabs]="tabs" [hideTabs]="true"></button-detail-list-larger>
</div>
