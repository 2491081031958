import { NgModule } from '@angular/core';
import { DISEASE_SECTIONS, WEBSITE_SECTIONS } from '../../shared/constants';
import { PATHS, PAGE_META } from '../../shared/paths';
import { Page } from '../../shared/location-enums';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EspanolDiseaseDetailComponent } from './components/disease-es/espanol-disease-detail.component';
import { DiseaseAboutComponent } from './components/disease/disease-page/disease-about.component';
import { DiseaseDiagnosisComponent } from './components/disease/disease-diagnosis-ARCHIVE/disease-diagnosis.component';
import { GenericPageComponent } from './components/generic/generic.component';
import { HomepageComponent } from './components/pages/home-page/homepage.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SearchResultPageComponent } from './components/search-result-page/search-result-page.component';
import { AccountsResolver } from './services/accounts.resolver';
import { ArticleParams } from './services/article.service';
import { ArticlesResolver } from './services/articles.resolver';
import { DiseaseResolver } from './services/disease.resolver';
import { LanguageResolver } from './services/language.resolver';
import { MetaResolver } from './services/meta.resolver';
import { SingleArticleResolver } from './services/single-article.resolver';
import { TrimmedDiseasesResolver } from './services/trimmed-diseases.resolver';
import { Language } from '../../shared/salesforce-types';
import { ErrorListComponent } from './testing/error-list/error-list.component';
import { ContactUsEsComponent } from './components/contact-us/contact-us-es/contact-us-es.component';
import { DiseasesCategoriesResolver } from './services/diseases-categories.resolver';
import { GardIdComponent } from './redirects/gard-id/gard-id.component';
import { ResourcesPageComponent } from './components/pages/resources-page/resources-page.component';
import { RedirectParentComponent } from './components/disease/redirect-parent/redirect-parent.component';

const routes: Routes = [
    {
        path: '',
        resolve: {
            articles: ArticlesResolver,
            accounts: AccountsResolver,
            diseases: TrimmedDiseasesResolver,
            diseasesCategories: DiseasesCategoriesResolver,
        },
        children: [
            {
                path: '',
                component: HomepageComponent,
                resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                data: {
                    meta: PAGE_META.home,
                    sectionId: WEBSITE_SECTIONS.home,
                    language: Language.En,
                },
            },
            {
                path: PATHS.gardIdRedirectPage,
                component: GardIdComponent,
            },
            {
                path: PATHS.homePageEspanol,
                component: HomepageComponent,
                resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                data: {
                    meta: PAGE_META[Language.Es].home,
                    sectionId: WEBSITE_SECTIONS.home,
                    language: Language.Es,
                },
            },
            {
                path: PATHS.diseases,
                component: SearchResultPageComponent,
                resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                data: {
                    sectionId: WEBSITE_SECTIONS.diseases,
                    meta: PAGE_META.diseaseSearch,
                    breadcrumbParents: [WEBSITE_SECTIONS.home],
                    language: Language.En,
                },
            },
            {
                path: PATHS.searchResultPage,
                component: SearchResultPageComponent,
                resolve: { metaResolver: MetaResolver },
                data: {
                    sectionId: WEBSITE_SECTIONS.diseases,
                    meta: PAGE_META.diseaseSearch,
                    breadcrumbParents: [WEBSITE_SECTIONS.home],
                },
            },
            {
                path: PATHS.searchResultEmpty,
                component: SearchResultPageComponent,
                resolve: { metaResolver: MetaResolver },
                data: {
                    sectionId: WEBSITE_SECTIONS.diseases,
                    meta: PAGE_META.diseaseSearch,
                    breadcrumbParents: [WEBSITE_SECTIONS.home],
                },
            },
            {
                path: PATHS.diseasePage,
                resolve: {
                    disease: DiseaseResolver,
                    metaResolver: MetaResolver,
                    languageResolver: LanguageResolver,
                },
                data: {
                    sectionId: WEBSITE_SECTIONS.disease,
                    breadcrumbParents: [WEBSITE_SECTIONS.home, WEBSITE_SECTIONS.diseases],
                    language: Language.En,
                },
                children: [
                    {
                        path: DISEASE_SECTIONS.about.link,
                        component: DiseaseAboutComponent,
                        data: { meta: PAGE_META.diseaseTabA, language: Language.En },
                        resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                    },
                    {
                        path: DISEASE_SECTIONS.diagnosis.link,
                        component: RedirectParentComponent,
                    },
                    {
                        path: DISEASE_SECTIONS.living.link,
                        redirectTo: '/resources',
                    },
                    {
                        path: DISEASE_SECTIONS.research.link,
                        redirectTo: '..',
                    },
                ],
            },
            {
                path: PATHS[Language.Es].diseases,
                component: SearchResultPageComponent,
                resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                data: {
                    meta: PAGE_META[Language.Es].diseaseSearch,
                    language: Language.Es,
                    sectionId: WEBSITE_SECTIONS.diseases,
                    breadcrumbParents: [WEBSITE_SECTIONS.home],
                },
            },
            {
                path: PATHS.diseasePageEspanol,
                component: EspanolDiseaseDetailComponent,
                resolve: { metaResolver: MetaResolver, languageResolver: LanguageResolver },
                data: {
                    meta: PAGE_META[Language.Es].diseasePage,
                    language: Language.Es,
                    sectionId: WEBSITE_SECTIONS.disease,
                    breadcrumbParents: [WEBSITE_SECTIONS.home, WEBSITE_SECTIONS.diseases],
                },
            },
            {
                path: '',
                data: {
                    language: Language.En,
                },
                resolve: {
                    languageResolver: LanguageResolver,
                },
                children: [
                    {
                        path: PATHS[Language.En].about,
                        component: AboutComponent,
                        data: {
                            sectionId: WEBSITE_SECTIONS.about,
                            meta: PAGE_META.about,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                        },
                        resolve: { metaResolver: MetaResolver },
                    },
                    {
                        path: PATHS[Language.En].contactUs,
                        component: ContactUsComponent,
                        resolve: { metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.contactUs,
                            meta: PAGE_META.contactUs,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                        },
                    },
                    {
                        path: PATHS[Language.En].resources,
                        component: ResourcesPageComponent,
                        resolve: { metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.resources,
                            meta: PAGE_META.resources,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                        },
                    },
                    {
                        path: PATHS[Language.En].privacyPolicy,
                        component: GenericPageComponent,
                        resolve: { article: SingleArticleResolver, metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.privacyPolicy,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            meta: PAGE_META.privacyPolicy,
                            singleArticleParams: {
                                tab: Page.privacyPolicy,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.En].disclaimer,
                        component: GenericPageComponent,
                        resolve: { metaResolver: MetaResolver, article: SingleArticleResolver },
                        data: {
                            meta: PAGE_META.disclaimer,
                            sectionId: WEBSITE_SECTIONS.disclaimer,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            singleArticleParams: {
                                tab: Page.disclaimer,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.En].accessibility,
                        component: GenericPageComponent,
                        resolve: { metaResolver: MetaResolver, article: SingleArticleResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.accessibility,
                            meta: PAGE_META.accessibility,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            singleArticleParams: {
                                tab: Page.accessibility,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.En].foia,
                        component: GenericPageComponent,
                        resolve: { metaResolver: MetaResolver, article: SingleArticleResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.foia,
                            meta: PAGE_META.foia,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            singleArticleParams: {
                                tab: Page.foia,
                            } as ArticleParams,
                        },
                    },
                ],
            },
            {
                path: '',
                data: {
                    language: Language.Es,
                },
                resolve: {
                    languageResolver: LanguageResolver,
                },
                children: [
                    {
                        path: PATHS[Language.Es].base,
                        component: HomepageComponent,
                    },
                    {
                        path: PATHS[Language.Es].about,
                        component: AboutComponent,
                        resolve: { metaResolver: MetaResolver },
                        data: {
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            sectionId: WEBSITE_SECTIONS.about,
                            meta: PAGE_META[Language.Es].about,
                        },
                    },
                    {
                        path: PATHS[Language.Es].contactUs,
                        component: ContactUsEsComponent,
                        resolve: { metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.contactUs,
                            meta: PAGE_META[Language.Es].contactUs,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                        },
                    },
                    {
                        path: PATHS[Language.Es].resources,
                        component: ResourcesPageComponent,
                        resolve: { metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.resources,
                            meta: PAGE_META[Language.Es].resources,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                        },
                    },
                    {
                        path: PATHS[Language.Es].privacyPolicy,
                        component: GenericPageComponent,
                        resolve: { article: SingleArticleResolver, metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.privacyPolicy,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            meta: PAGE_META[Language.Es].privacyPolicy,
                            singleArticleParams: {
                                tab: Page.privacyPolicy,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.Es].disclaimer,
                        component: GenericPageComponent,
                        resolve: { article: SingleArticleResolver, metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.disclaimer,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            meta: PAGE_META[Language.Es].disclaimer,
                            singleArticleParams: {
                                tab: Page.disclaimer,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.Es].accessibility,
                        component: GenericPageComponent,
                        resolve: { article: SingleArticleResolver, metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.accessibility,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            meta: PAGE_META[Language.Es].accessibility,
                            singleArticleParams: {
                                tab: Page.accessibility,
                            } as ArticleParams,
                        },
                    },
                    {
                        path: PATHS[Language.Es].foia,
                        component: GenericPageComponent,
                        resolve: { article: SingleArticleResolver, metaResolver: MetaResolver },
                        data: {
                            sectionId: WEBSITE_SECTIONS.foia,
                            breadcrumbParents: [WEBSITE_SECTIONS.home],
                            meta: PAGE_META[Language.Es].foia,
                            singleArticleParams: {
                                tab: Page.foia,
                            } as ArticleParams,
                        },
                    },
                ],
            },
            {
                path: '**',
                component: NotFoundComponent,
                resolve: { articles: ArticlesResolver },
            },
        ],
    },
    {
        path: 'errors',
        component: ErrorListComponent,
    },
    {
        path: PATHS.notFound,
        component: NotFoundComponent,
        resolve: { articles: ArticlesResolver },
    },
    {
        path: '**',
        component: NotFoundComponent,
        resolve: { articles: ArticlesResolver },
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
