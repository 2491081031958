<app-top-navigation></app-top-navigation>
<main-banner [disease]="disease"></main-banner>

<disease-at-a-glance [id]="sections.disease.id"></disease-at-a-glance>

<causes [id]="sections.causes.id"></causes>

<inheritance></inheritance>

<age-at-onset></age-at-onset>

<symptoms-section></symptoms-section>

<diagnostic-journey></diagnostic-journey>

<your-medical-team></your-medical-team>

<rare-disease-expert></rare-disease-expert>

<line-break-shadow-below></line-break-shadow-below>

<!-- A5 Find Your Community -->
<advocacy-and-support-groups></advocacy-and-support-groups>

<!-- A6 PAG Accounts -->
<patient-organizations-accounts></patient-organizations-accounts>

<!-- A7 -->
<participating-in-clinical-studies [id]="sections.clinicalStudies.id"></participating-in-clinical-studies>

<!-- A8 -->
<clinical-studies></clinical-studies>

<!-- A9 -->
<clinical-trials-contact-us></clinical-trials-contact-us>

<div class="mb-5"></div>

<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
